.progress-bar {
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  height: 3px;
  background-color: #ffffff;
  transition: width 1s ease-in-out;
  /* transform-origin: left center; */
}

.progress-bar.reset {
  transition: none;
  /* width: 0; */
  /* transform: scaleX(0); */
}

/* .progress-bar {
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  height: 4px;
  background-color: #007bff;
  transform-origin: left center;
}

.progress-bar.reset {
  transition: none;
  transform: scaleX(0);
} */
