/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.spinner {
  animation: spin infinite 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

html * {
  /* font-family: 'Courier New', monospace; */
  /* font-family: 'Trebuchet MS', sans-serif; */
  /* font-family: Georgia, serif; */
  /* font-family: Verdana, sans-serif; */
  font-family: Arial, sans-serif !important;
}

body {
  background-color: #e9eefb !important;
}

.pointer {
  cursor: pointer;
}
